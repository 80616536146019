
import schttp from 'public/src/services/schttp/index.js'
import { abortRequest, requestProductListApi } from 'public/src/pre_requests/modules/productList/index.js'
import timeCollectionManager from './timeCollectionManager.js'
import {  SILogger } from 'public/src/pages/common/monitor/index'
/**
 *  请求seo
 */
export const fetchSeoInfo = async(params)=>{
  try{
    const resData = await schttp({
      url: '/category-api/web/ccc-seo',
      method: 'GET',
      params,
      useBffApi: true,
    })
    const { code, info } = resData || {}
    if(code !== '0' || !info){
      throw resData
    }
    return info
  }catch(error){
    return null
  }
}

/**
 * promo tab promo 接口请求 --复用
 */
export const fetchPromotionInfo = async(params, option)=>{
  const { signal } = option || {}
  try {
    const resData = await schttp({
      url: '/ccc/store/promotion',
      method: 'GET',
      params,
      signal,
      useBffApi: true,
    })
    const { code, info } = resData || {}
    if(code !== '0' || !info){
      throw resData
    }
    const { flashSale, promotions, discountTabs } = info || {}
    const storePromoFlashData = { promotion: flashSale, 
      list: [], 
    } || {}
    const isStorePromoFlashData = !!flashSale
    // 促销活动
    const promotionInfo = promotions || []
    const isPromotionInfo = promotionInfo.length > 0
    // 折扣tab
    const discountProductTabs = discountTabs || []
    const isDiscountProductTabs = discountProductTabs.length > 0
    // 是否展示 promo tab
    const hasPromotionTab = isStorePromoFlashData || isPromotionInfo || isDiscountProductTabs

    const result = {
      storePromoFlashData,
      isStorePromoFlashData,
      promotionInfo,
      isPromotionInfo,
      discountProductTabs,
      isDiscountProductTabs,
      hasPromotionTab,
      isReady: true
    }
    return result
  } catch (error) {
    return {
      isReady: false
    }
  }
}

/**
 * 店铺主接口客户端拆分请求
 */
class StoreCleintRequest{
  static schttpAbortCon = null

  static getInstance(){
    return new StoreCleintRequest()
  }
  static subscribeTasks = {} // 生产订阅任务
  static subcribeTaskConsumerManagers = {} // 订阅任务消费者管理

  static onSubscribeTask(type, data){ // 订阅任务
    if(typeof StoreCleintRequest.subcribeTaskConsumerManagers[type] === 'function'){ // 直接消费
      StoreCleintRequest.subcribeTaskConsumerManagers[type](data, type)
    }else{
      StoreCleintRequest.subscribeTasks[type] = data // 保存数据
    }
  }

  static onSubcribeConsumer(type, fn){ // 订阅消费者
    StoreCleintRequest.subcribeTaskConsumerManagers[type] = fn // 订阅消费者
    if(StoreCleintRequest.subscribeTasks[type]){
      fn(StoreCleintRequest.subscribeTasks[type], type) // 消费
    }
  }

  static clearAllSubcribe(){
    StoreCleintRequest.subscribeTasks = {}
    StoreCleintRequest.subcribeTaskConsumerManagers = {}
  }

  constructor(){
    this.requestParams = {}
    this.requestOptions = {}
  }

  handleStoreTabConfig(storeData){
    const { middleTabs = [], targetTab } = storeData || {}
    let tabId = 'allItem' // item tab
    const tabTypeMap = { // 映射适配
      item: 'allItem',
      promo: 'promotion',
    }
    let topTabs = middleTabs.map((item)=>tabTypeMap[item.tabType] || item.tabType)

    if(topTabs.length === 0){
      topTabs = ['allItem']
    }
    if(targetTab){ // 全模版
      tabId = tabTypeMap[targetTab] || targetTab
    }
    // tabId
    if(tabId === 'items'){
      tabId = 'allItem'
    }
    tabId = tabId && topTabs.includes(tabId) ? tabId :  topTabs[0]
    return {
      topTabs,
      topTabActive: tabId,
      showTopTab: topTabs.length > 1
    }
  }
  // 请求 多语言， abt 等
  async requestStoreBasicInfo(){
    try {
      const startTime = Date.now()
      const { store_code,  } = this.requestParams || {}
      const resData = await schttp({
        url: '/api/store/page/basic/info',
        method: 'GET',
        params: {
          store_code, 
        },
        signal: StoreCleintRequest.schttpAbortCon.signal,
      })
      if(!resData){
        throw new Error('requestStoreBasicInfo resData is emptyr')
      }
      const { code, info, nodeServerTime } = resData
      if(code !== '0' || !info){
        throw resData
      }
      timeCollectionManager.setTime('requestStoreBasicInfoNodeServerTime', nodeServerTime)
      timeCollectionManager.setTime('requestStoreBasicInfoTime', Date.now() - startTime)
      return info
    } catch (error) {
      SILogger.logInfo({
        tag: 'store_page_client', // 店铺客户端日志异常筛选标识
        message: error?.msg || error?.message  || 'requestStoreBasicInfo default error message',
        params: {
          scene: 'store_requestStoreBasicInfo_error',
        }
      })
      throw error
    }
  }
  // 请求 bff store/info 接口
  async requestStoreInfo(){
    try {
      const startTime = Date.now()
      const { store_code,  } = this.requestParams || {}
      const resData = await schttp({
        url: '/ccc/store/info',
        method: 'GET',
        params: {
          storeCode: store_code, 
          targetTab: 'item', // home 全模版向item切换
          // content_id, // home tab 预览 
        },
        signal: StoreCleintRequest.schttpAbortCon.signal,
        useBffApi: true,
      })
      if(!resData){
        throw new Error('requestStoreInfo resData is emptyr')
      }
      const { code, info } = resData
      if(code !== '0' || !info){
        throw resData
      }
      timeCollectionManager.setTime('requestStoreInfoTime', Date.now() - startTime)
      const { signboard } = info
      const productsNum = parseFloat(signboard?.productsNum)
      const quantity = isNaN(productsNum) ? 0 : productsNum
      Object.assign(info, { quantity })
      return info
    } catch (error) {
      throw error
    }
  }
  // bff 请求促销条信息
  async requestStorePromotionStrip(){
    try {
      const startTime = Date.now()
      const { store_code, } = this.requestParams || {}
      const resData = await schttp({
        url: '/ccc/store/promotion',
        method: 'GET',
        params: {
          storeCode: store_code, 
          isPromoPop: '1'
        },
        signal: StoreCleintRequest.schttpAbortCon.signal,
        useBffApi: true,
      })
      if(!resData){
        throw new Error('requestStorePromotionStrip resData is empty')
      }
      const { code, info } = resData
      if(code !== '0' || !info){
        throw resData
      }
      timeCollectionManager.setTime('requestStorePromotionStripTime', Date.now() - startTime)
      return info
    } catch (error) {
      return {}
    }
  }
  // 集成store 头部信息 公共头，店招， 促销条，tab
  async genRequstStoreHeadInfo(){
    try {
      const startTime = Date.now()
      const tasks = [
        this.requestStoreInfo(),
        this.requestStorePromotionStrip(),
        this.requestStoreBasicInfo(),
      ]
      const resData = await Promise.all(tasks)
      const [ storeData, storePromotionStrip, storeBasicInfo] = resData || []
      const storeTabConfig = this.handleStoreTabConfig(storeData)
      const result = {
        ...storeBasicInfo,
        storeData,
        storeTabConfig,
        storePromotionStrip,
      }
      // 触发结束
      StoreCleintRequest.onSubscribeTask('storeHeadInfoReady', result)
      timeCollectionManager.setTime('genRequstStoreHeadInfoTime', Date.now() - startTime)
      return result
    } catch (error) {
      throw error
    }
  }
  // 请求 店铺 item tab list 聚合接口
  async requestStoreItemTabListNode(){
    try {
      const startTime = Date.now()
      const resData = await schttp({
        url: '/api/store/page/item/info',
        method: 'GET',
        params: this.requestParams || {},
        signal: StoreCleintRequest.schttpAbortCon.signal,
      })
      if(!resData){
        throw new Error('requestStoreAggregationInfo resData is emptyr')
      }
      const { code, info, nodeServerTime } = resData
      if(code !== '0' || !info || !info.listResult){
        throw resData
      }
      if(info.listResult){
        Object.assign(info.listResult, { isStoreItemDataReady: true })
      }
      timeCollectionManager.setTime('requestStoreItemTabListNodeServerTime', nodeServerTime)
      timeCollectionManager.setTime('requestStoreItemTabListNodeTime', Date.now() - startTime)
      return info
    } catch (error) {
      SILogger.logInfo({
        tag: 'store_page_client', // 店铺客户端日志异常筛选标识
        message: error?.msg || error?.message  || 'requestStoreItemTabListNode default error message',
        params: {
          scene: 'store_requestStoreItemTabListNode_error',
        }
      })
      return { listResult: { isStoreItemDataReady: false } }
    }
  }

  async requestStoreItemTabListBff(){
    abortRequest() // 取消之前的列表请求
    const { route }  = this.requestOptions || {}
    try {
      const startTime = Date.now()
      const requestInfo = {
        type: 'firstload',
        query: {
          ...this.requestParams,
          page: 1,
          limit: 20,
          isStoreListPreRequest: true, // 标识是提前请求
        }
      }
      const listResult = await requestProductListApi({ requestInfo, newSrcTabPageId: null, toRoute: route })
      if(!listResult || Object.keys(listResult).length === 0){
        throw listResult
      }
      Object.assign(listResult, { isStoreItemDataReady: true })
      timeCollectionManager.setTime('requestStoreItemTabListBffTime', Date.now() - startTime)
      return { listResult }
    } catch (error) {
      SILogger.logInfo({
        tag: 'store_page_client', // 店铺客户端日志异常筛选标识
        message: error?.msg || error?.message  || 'requestStoreItemTabListBff default error message',
        params: {
          scene: 'store_requestStoreItemTabListBff_error',
          url: route.fullPath
        }
      })
      return { listResult: { isStoreItemDataReady: false } }
    }
  }


  async request(params, options){
    try {
      if(!params.store_code){ // store_code 没有
        throw {
          code: '-1',
          info: null,
          msg: 'storeCode is not valid',
          metricStatus: '1', // 不归类失败上报
          toErrorPage: true,
        }
      }
      const startTime = Date.now()
      this.requestParams = params || {}
      this.requestOptions = options || {}
      StoreCleintRequest.clearAllSubcribe()
      if(StoreCleintRequest.schttpAbortCon){
        StoreCleintRequest.schttpAbortCon.abort()
      }
      StoreCleintRequest.schttpAbortCon = new SchttpAbortCon()
     
      const tasks = [
        this.genRequstStoreHeadInfo(), // tab 以上信息
      ]
      let _requestMarking = 'Others'
      if(this.isStoreItemTabClientBff()){
        _requestMarking = 'client_bff'
        tasks.push(this.requestStoreItemTabListBff()) // 直接请求bff
      }else{
        _requestMarking = 'client_node'
        tasks.push(this.requestStoreItemTabListNode()) // node 聚合 bff
      }
      const resData = await Promise.all(tasks)
      const [storeHeadInfo, storeItemListInfo] = resData || []
      const result = {
        ...storeHeadInfo,
        ...storeItemListInfo,
        _requestMarking, // 标记请求来源，用于上报分析
      }
      timeCollectionManager.setTime('storeCleintRequestTime', Date.now() - startTime)
      // console.log("========result", result)
      return result
    } catch (error) {
      throw error
    }finally{
      // eslint-disable-next-line require-atomic-updates
      StoreCleintRequest.schttpAbortCon = null
      StoreCleintRequest.clearAllSubcribe()
    }
  }

  isStoreItemTabClientBff(){
    let { STORE_PAGE_CLIENT_REQUEST_CONFIG  } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
    if(localStorage.getItem('STORE_PAGE_CLIENT_REQUEST_CONFIG')){
      try{
        STORE_PAGE_CLIENT_REQUEST_CONFIG = JSON.parse(localStorage.getItem('STORE_PAGE_CLIENT_REQUEST_CONFIG'))
      }catch(erorr){
        console.log('==========erorr', erorr)
      }
    }
    const { itemClientBffRandom = 0 } = STORE_PAGE_CLIENT_REQUEST_CONFIG || {}
    return Math.random() < itemClientBffRandom
  }
}

export default  StoreCleintRequest
