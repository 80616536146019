import { getQueryString, stringifyQueryString } from '@shein/common-function'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'



export const removeURLParameter = (key) => {
  if (typeof window === 'undefined') return
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.delete(key)
  // 使用 replaceState 修改当前历史条目的 URL 和状态
  window.history.replaceState(history.state, '', currentUrl.toString())
}

// 店铺开发提示log -- 仅仅在开发环境打印
export const storePageTipLog = ()=>{
  if(typeof window === 'undefined') return
  const { NODE_SERVER_ENV } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
  // 本地开发
  if(NODE_SERVER_ENV === 'localhost' && !localStorage.IGNORE_STORE_PAGE_NEW_ACCESS_URL_LOG){
    // eslint-disable-next-line no-console
    console.log(`%c重磅-店铺新增访问方式: ${location.pathname}(店铺名称-store-storecode.html)`, 'color:red')
    // eslint-disable-next-line no-console
    console.log(' 1.旧的访问方式(store/home?store_code=xxxx)保留，后续将逐步向新的访问方式迁移,本地开发会重定向到新的访问方式链接\n',
      '2.新的访问方式无法从location.search中获取store_code, 所以在店铺页面禁止通过getQueryString获取store_code\n',
      '3.建议店铺中可通过storeData.storeCode 获取Store_code, cccx装修组件通过sceneData.storeCode 获取\n',
      '4.新的店铺访问方式，中间层依然可以通过req.query.store_code 获取，客户端依然可以通过this.$route.query.store_code获取\n',
      '5.提供了兼容新旧店铺访问方式获取链接中store_code方法:getStoreCodeFromUrl和跳转到店铺方法：jumpToStorePage\n',
      '6.该log只在店铺本地开发时打印提示, 可通过执行 localStorage.IGNORE_STORE_PAGE_NEW_ACCESS_URL_LOG=1 屏蔽'
    )
  }
}

export const getStoreCodeByUrlString = (url) => {
  const reg = /.+-store-(\d{10})\.html/
  const matchResult = url.match(reg) || []
  return matchResult[1] || ''
}


/** 
 * 从url中获取 storeCode: 兼容从 location.search 和 location.pathname 中获取
*/
export const getStoreCodeFromUrl = () => {
  if(typeof window === 'undefined') return '' // 非客户端调用
  let storeCode = getQueryString({ key: 'store_code' })
  if(!storeCode && location.pathname){ // 从 /店铺名称-store-8789832178.html 中获取
    const reg = /.+-store-(\d{10})\.html$/ // store reg
    const matchResult = location.pathname.match(reg) || []
    storeCode = matchResult[1] || ''
  }
  return storeCode || ''
}



/** 
 * 跳转到店铺
 * params
*/
export const jumpToStorePage = (params, options)=>{
  if(typeof window === 'undefined') return // 非客户端调用
  const {
    isPreloadEnabled = true,
    replace = false, // push or replace
    useHref = false
  } = options || {}
  const { store_name, store_code, ...restParam } = params || {}
  // 确保 传入 store_name 和 store_code
  if(!store_name || !store_code){
    throw new Error(`请确保在params中传入store_name和store_code`)
  }
  const { langPath = '', STORE_PAGE_STORE_CODE_IN_PATH_ACCESS = 'on' } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
  let path = langPath
   
  let queryObj = restParam || {}
  // apollo 控制
  if(STORE_PAGE_STORE_CODE_IN_PATH_ACCESS === 'on'){ // 推荐使用
    const _storeName = encodeURIComponent(store_name.trim().split(/\s+/g).join('-'))
    path += `/${_storeName}-store-${store_code}.html`
    queryObj = { store_code, ...restParam }
    path += `?${stringifyQueryString({ queryObj })}`
  }else{
    queryObj = { store_code, ...restParam }
    path += `/store/home?${stringifyQueryString({ queryObj })}`
  }
  const $router = window._gb_app_?.$router
  if($router && !useHref){
    if (isPreloadEnabled) {
      storePageDataManager.prerenderRequest({
        fullPath: path,
        query: queryObj,
      })
    }
    if(replace){
      $router.replace(path)
    }else{
      $router.push(path)
    }
  }else{
    window.location.href = window.location.origin + path
  }
}


/**
 * format seo 数据
 */

export const formatStoreSeoResult = (result, storeData) => {
  let { pageTitle, pageDescription, pageKeywords } = result || {}
  const { title: storeName = '' } = storeData || {}
  if (!pageTitle) {
    pageTitle = '[st_name] | SHEIN'
  }
  if (!pageDescription) {
    pageDescription =
      'Free Returns ✓ Free Shipping On Orders $49+ ✓ 1000+ New Arrivals Dropped Daily ✓ Shop for [st_name] at SHEIN!'
  }
  if (!pageKeywords) {
    pageKeywords = '[st_name]'
  }

  return {
    pageTitle: pageTitle.replace(/\[st_name\]/g, storeName),
    pageDescription: pageDescription.replace(/\[st_name\]/g, storeName),
    pageKeywords: pageKeywords.replace(/\[st_name\]/g, storeName),
  }
}
