<!-- slide不支持异步加载, 封装一层使用 -->
<template>
  <div>
    <ClientOnly>
      <SSlide
        ref="refSlide"
        v-model:visible="myVisible"
        :modal="true"
        :close-on-click-modal="!loading"
        @beforeOpen="onDisabledScroll"
        @beforeClose="onDisabledScroll"
        @close-from-mask="clickOverlay"
        @vue:mounted="onSlideMounted"
      >
        <!-- 自定义panel内容 -->
        <slot></slot>
      </SSlide>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DropdownPanel',
})
</script>

<script setup>
import { ref, watch, onMounted, getCurrentInstance } from 'vue'
import { SSlide } from '@shein-aidc/sui-slide/mobile'
import { ClientOnly } from '@sheinfe/vue-client-only'

// utils
import { throttle } from '@shein/common-function'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'
import { onUpdateDropdownPanelHeight, offUpdateDropdownPanelHeight, emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { useEventListener } from '../../hooks/useEventListener'

defineExpose({ open, close })
const emits = defineEmits(['open', 'close', 'update:visible'])
const props = defineProps({
  loading: { type: Boolean, default: false },
  visible: { type: Boolean, default: false },
})

const refSlide = ref(null)
let curInstance = ref(null)
const myVisible = ref(props.visible)

// 更新slide的top值
const updateSlideTop = async () => {
  // 非slide蒙层，不需要更新
  if (!myVisible.value) return

  // 临时方案，后续优化
  const slot = curInstance.value?.proxy?.$slots?.default?.()?.[0]
  const parentSlot = slot?.ctx?.proxy?.$slots?.default?.()?.[0]
  const selectRef = parentSlot?.ctx?.ctx?.getAllRefSelect?.()
  if (selectRef?.adapterHeight) {
    await selectRef.adapterHeight()
  }
  refSlide.value?.calHeight?.()
}

// 点击遮罩层
const clickOverlay = () => {
  if (props.loading) return
  
  emits('close')
}
const onDisabledScroll = () =>  setDisabledScroll()

const onSlideMounted = () => {
  const timer = setTimeout(() => {
    clearTimeout(timer)
    updateSlideTop()
  }, 300) // 等待300ms动画后再计算高度
}

// 同步visible -> myVisible
watch(() => props.visible, (val) => {
  myVisible.value = val
})

// 监听visible变化, 锁定body, 监听onBranchHide/updatePanelTop事件
watch(myVisible,  (val) => {
  if (typeof window === 'undefined') return
  emits('update:visible', val)
  if (window?.vBus) {
    window.vBus.off('onBranchHide')
    window.vBus.on('onBranchHide', updateSlideTop)
  }

  if (window.appEventCenter) {
    window.appEventCenter.off('updatePanelTop')
    window.appEventCenter.on('updatePanelTop', updateSlideTop)
  }
  if (val) {
    onUpdateDropdownPanelHeight(updateSlideTop)
    emitUpdateDropdownPanelHeight(300)
  } else {
    offUpdateDropdownPanelHeight()
  }
})

const handleResize = throttle({
  func: updateSlideTop,
  wait: 100,
  options: { leading: false }
})
useEventListener('resize', handleResize)

onMounted(async () => {
  curInstance.value = getCurrentInstance()
  if (!myVisible.value ) return
  offUpdateDropdownPanelHeight()
  onUpdateDropdownPanelHeight(updateSlideTop)
  emitUpdateDropdownPanelHeight(300)
})
</script>
