import { setupHydrationVue } from 'public/src/spaHydration/index.js'
import { storeModuleName } from 'public/src/pages/product_app/store/modules/product_list_v2/index.js'

export default {
  beforeCreate() {
    const context = setupHydrationVue()
    if (context) {
      this.$store.state[storeModuleName].context = context.contextForSSR
    }
  },
}
