

import schttp from 'public/src/services/schttp'
import { getStoreCodeByUrlString, 
} from 'public/src/pages/store_pages/js/utils.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { getDefaultKidParamsByAb } from 'public/src/pages/components/FilterBar/utils/kids.js'
import timeCollectionManager from './timeCollectionManager.js'
import storeCleintRequest from './storeCleintRequest.js'


class StorePageDataManager {
  constructor() {
    // 单例模式
    if (StorePageDataManager.instance) {
      return StorePageDataManager.instance
    }
    this.init()
    StorePageDataManager.instance = this

  }
  init() {
    this.fromFullPath = ''
    this.fullPath = ''
    this.schttpAbortCon = null
    this.pageDataRequest = null
    this.pageSsrDataResolved = false
  }

  isStoreClientRequest(route){
    const tabId = route.query?.tabId
    let { STORE_PAGE_CLIENT_REQUEST_CONFIG  } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
    if(localStorage.getItem('STORE_PAGE_CLIENT_REQUEST_CONFIG')){
      try{
        STORE_PAGE_CLIENT_REQUEST_CONFIG = JSON.parse(localStorage.getItem('STORE_PAGE_CLIENT_REQUEST_CONFIG'))
      }catch(erorr){
        console.log('==========erorr', erorr)
      }
    }
    const {  storeClientBffRandom = 0 } = STORE_PAGE_CLIENT_REQUEST_CONFIG || {}
    return (tabId === 'allItem' || tabId === 'items') && Math.random() < storeClientBffRandom // 0-1
  }
  async prerenderRequest(route) {
    return this.request(route)
  }
  request(route) {
    if (typeof window === 'undefined') return null
    if (!route) return null
    if (route.fullPath !== this.fullPath) {
      this.fullPath = route.fullPath
      timeCollectionManager.reset()
      markPoint({ eventName: 'waitPageData', measureFrom: 'toNextPageClick' })
      if(this.isStoreClientRequest(route)){
        this.pageDataRequest = this.fetchstoreClientData(route)
      }else{
        this.pageDataRequest = this.fetchPageData(route)
      }
      
    }
    return this.pageDataRequest
  }

  getPathnameFromFullPath(fullPath){
    if(!fullPath) return ''
    const index = fullPath.indexOf('?')
    if(index >= 0){
      return fullPath.slice(0, index)
    }
    return fullPath
  }

  getRequestParams(route){
    const store_code = route.query?.store_code || getStoreCodeByUrlString(route.fullPath) // 获取storeCode
    const pathname = this.getPathnameFromFullPath(route.fullPath)
    const params = {
      ...route.query,
      ...getDefaultKidParamsByAb(pathname),
      store_code,
      isInfoData: '1',
      i18n: true,
    }
    return params
  }
  async fetchstoreClientData(route){
    try {
      if(!route.name){
        route.name = 'page_store' // 点击请求，模拟的route只有
      }
      return storeCleintRequest.getInstance().request(this.getRequestParams(route), { route })
    } catch (error) {
      this.fullPath = ''
      return this.handleRequestError(error)
    }
  }
  async fetchPageData(route) {
    if (this.schttpAbortCon) {
      this.schttpAbortCon.abort()
    }
    this.schttpAbortCon = new SchttpAbortCon()
    const params = this.getRequestParams(route)
    try {
      if(!params.store_code){
        throw {
          code: '-1',
          info: null,
          msg: 'storeCode is not valid',
          metricStatus: '1', // 不归类失败上报
          toErrorPage: true,
        }
      }
      const startTime = Date.now()
      const resData = await schttp({
        url: '/api/store/page/get',
        params,
        signal: this.schttpAbortCon.signal,
        schttp: { getResponse: true },
      })
      // 没有 data
      if(!resData?.data || typeof resData.data !== 'object'){ // todo
        throw resData
      }
      const { code, info, msg, traceId } = resData.data
      if(code !== '0' || !info){
        if(!msg){
          resData.data.msg = `resData.data is not valide`
        }
        if(!traceId){
          resData.data.traceId = resData.headers?.['x-origin-trace-id'] || resData.config?.headers?.['uber-trace-id']
        }
        resData.name = 'customError'
        throw resData.data
      }
      timeCollectionManager.setTime('storePageGetRequestTime', Date.now() - startTime)
      markPoint({ eventName: 'pageDataReady',  measureFrom: 'waitPageData' })
      Object.assign(info, {
        _requestMarking: 'store_page_get'
      })
      return info
    } catch (error) {
      this.fullPath = ''
      this.handleRequestError(error)
    }
  }

  handleRequestError(error){
    if(!error || typeof error !== 'object'){ // 只能走默认日志上报了
      return {
        isError: true,
        msg: 'store page get default error message',
      }
    }
    // 返回错误信息--SILogger 上报到skynet
    return {
      isError: true,
      msg: `code:${error.code} | message:${error.msg || error.message || '' }`,
      traceId: error.traceId || error.headers?.['x-origin-trace-id'] || error.config?.headers?.['uber-trace-id'],
      name: error.name || 'defaultErrorName',
      metricStatus: error.metricStatus || '0', // metricStatus：1有些异常从失败上报中移除
      toErrorPage: error.toErrorPage, // 重定向跳转到 404 页面
    }
  }

  reset() {
    this.init()
  }

}

export default new StorePageDataManager()

