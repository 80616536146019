import { computed } from 'vue'
import { useMapGetters } from './useVuex.js'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'

function useClickPushBackProducts() {
  const { 
    storeAbtResult,
  } = useMapGetters([
    'storeAbtResult',
  ])

  const clickPushBackProductsConfig = computed(() => {
    const { StoreClickReco } = storeAbtResult.value || {}
    const StoreClickRecoEntry = StoreClickReco?.p?.StoreClickRecoEntry
    const StoreClickRecoTime = StoreClickReco?.p?.StoreClickRecoTime
    const isOpenClickPushBackProducts = !!StoreClickRecoEntry && !!StoreClickRecoTime
    let emitNotAddCart = false
    let emitAddCart = false
    let emitClick = false
    let emitClickWithAddBag = false
    switch (StoreClickRecoTime) {
      case 'click':
        emitNotAddCart = true
        emitAddCart = true
        emitClick = true
        emitClickWithAddBag = true
        break
      case 'addBag':
        emitNotAddCart = false
        emitAddCart = true
        emitClick = false
        emitClickWithAddBag = true
        break
      case 'noaddBag':
        emitNotAddCart = true
        emitAddCart = false
        emitClick = true
        emitClickWithAddBag = false
        break
      default:
        break
    }

    return {
      scene: 'store', // 点后推场景值
      emitNotAddCart: isOpenClickPushBackProducts && emitNotAddCart, // 在加车未成功后触发点推
      emitAddCart: isOpenClickPushBackProducts && emitAddCart, // 在加车成功后触发点推
      emitClick: isOpenClickPushBackProducts && emitClick, // 在点击后触发点推请求，并在商详返回后再展示点推
      emitClickWithAddBag: isOpenClickPushBackProducts && emitClickWithAddBag, // 在点击进入商详加车并返回页面后，触发点推
      needFilterDetailsExposeGoods: true, // 在点击商卡后请求需要过滤商详推荐相关数据
      num: StoreClickRecoEntry, // 点后推数量
      limitAllTab: false, // 限制只有All tab能进行请求
      extraRequestParams: {
        storeCode: getStoreCodeFromUrl(),
      }
    }
  })

  return {
    clickPushBackProductsConfig,
  }
}

export default useClickPushBackProducts
